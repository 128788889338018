







/* eslint-disable max-len */

import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Hero from '@/components/Hero.vue'

export default defineComponent({
  name: 'flexible-content',
  components: {
    Hero,
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
