





























import { ref, onMounted, defineComponent } from '@vue/composition-api'
import { gsap } from 'gsap'
import CustomEase from 'gsap/dist/CustomEase.js'
import SplitText from 'gsap/dist/SplitText.js'

gsap.registerPlugin(SplitText)

export default defineComponent({
  name: 'home-hero',
  props: {
    content: Object,
  },
  setup() {
    const picture = ref<HTMLElement | null>(null)
    const pictureContainer = ref<HTMLElement | null>(null)

    onMounted(() => {
      // Const heroContent = document.querySelector('.hero__content')

      const title = document.querySelector('.hero__content__title')
      const splitTitle = new SplitText(title, { type: ['words'] })
      const baseline = document.querySelector('.hero__content__baseline')

      const clipProgress = {
        y: 100,
      }

      const tl = gsap.timeline()

      const customEase = CustomEase.create(
        'custom',
        'M0,0 C0.262,0.75 -0.198,0.998 1,1 '
      )

      tl.add('start')
        // .set(heroContent, {
        //   opacity: 0,
        //   y: 50,
        // })
        .set(picture.value, {
          scale: 1.2,
        })
        .set(baseline, {
          opacity: 0,
          y: 50,
        })
        // .to(
        //   heroContent,
        //   {
        //     duration: 2,
        //     opacity: 1,
        //     y: 0,
        //     ease: customEase,
        //   },
        //   'start'
        // )
        .from(
          clipProgress,
          {
            duration: 7,
            y: 0,
            ease: customEase,
            onUpdate: () => {
              if (pictureContainer.value) {
                pictureContainer.value!.style.clip = `rect(0, 100vw, ${clipProgress.y}vh , 0 )`
              }
            },
          },
          'start'
        )
        .to(
          picture.value,
          {
            duration: 2.5,
            scale: 1,
            ease: customEase,
          },
          'start'
        )

        .from(
          splitTitle.words,
          {
            duration: 1.5,
            opacity: 0,
            stagger: 0.15,
            y: 50,
            ease: customEase,
          },
          '<0.5'
        )
        .to(splitTitle.words, {
          duration: 1.5,
          opacity: 1,
          stagger: 0.5,
          ease: customEase,
        })
        .to(
          baseline,
          {
            duration: 4,
            opacity: 1,
            y: 0,
            ease: customEase,
          },
          '<-6'
        )
    })

    return {
      picture,
      pictureContainer,
    }
  },
})
