





















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Card from '@/components/Card.vue'
import Hero from '@/components/Hero.vue'

export default defineComponent({
  name: 'news-page',
  components: {
    Card,
    Hero,
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
