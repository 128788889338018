







/* eslint-disable max-len */

import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Hero from '@/components/home/Hero.vue'

export default defineComponent({
  name: 'home-page',
  components: {
    Hero,
  },
  setup() {
    const { content } = useGetters(['content'])
    const afterHero = ref(false)

    onMounted(() => {
      setTimeout(() => {
        afterHero.value = true
      }, 2000)
    })

    return {
      afterHero,
      content,
    }
  },
})
